@import '../../../../variables.scss';

.custom-message-drawer{
    .ant-drawer-header{
        font-family: $heading-stack;
        font-weight: bold;
        .ant-drawer-title{
            font-weight: bold;
        }
    }
    .ant-drawer-body{
        margin-top: 3vh;
        .ant-form-item-label{
            label{
                text-transform: uppercase;
            }
        }
    }
}