@import '../../../../variables.scss';

.general-queue-component{
    .queue-btn{
        position: absolute;
        top: 120px;
        right: 0px;
        box-shadow: 0px 0px 12px rgba(0,0,0,0.15);
    }
}
.queue-drawer{
    .ant-drawer-body{
        display: flex;
        flex-direction: column;
    }
}
.general-queue-event-container{
    width: 100%;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 5px;
    height: 70px;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    box-shadow: 0px 0px 10px tgba(0,0,0,0.2);
    h3{
        font-size: 16px;
        margin: 0;
        padding: 0;
        flex-grow: 1;
    }
    .schedule-details{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 40%;
        min-width: 240px;
        height: 100%;
        margin-right: 50px;

        .time-container{
            h3{
                font-family: $heading-stack;
                font-weight: bold;
                margin: 0;
                padding: 0;
                font-size: 16px;
            }
            p{
                margin: 0;
                padding: 0;
                font-size: 14px;
            }
        }
    }
    .schedule-icon{
        font-size: 20px;
        color: rgba(0,0,0,0.3);
        &.time-icon{
            margin-right: 20px;  
        }
        
        &.exit-icon{
            padding: 5px;
            border-radius: 3px;
            cursor: pointer;

            &:hover{
                background-color: rgba(0,0,0,0.1);
            }

        }
    }
}