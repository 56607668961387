@import '../../variables.scss';

.landing-page{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0px 3vw;
    height: 100%;
    margin-top: -60px;
    .left-container{
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h2{
            font-family: $heading-stack;
            font-size: 50px;
            color:#313131;
            font-weight: bold;
            margin: 0;
            
        }
        p{
            font-size: 16px;
            line-height: 25px;
            margin: 3vh 0vh 7vh 0;
        }
        .center-align{
            align-self: center;
            .cta-btn{
                margin-top: 4vh;
                text-transform: uppercase;
                background-color: $primary-color;
                color: white;
                border: 1px solid $primary-color;
                width: 220px;
                height: 60px;
                align-self: center;
                
            }
        }
    }
    .right-container{
      img{
          width: 100%;
      }
    }
}