@import '../../../../variables.scss';

.schedule-container{
    background-color: $container-primary;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: 1px solid rgba(0,0,0,0.15);
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: default;
    height: 100%;

    
    h2{
        font-family: $heading-stack;
        font-weight: bold;
        margin-bottom: 5vh;
    }
    .select-date-container{
        position: relative;

        .datepicker-modal{
            position: absolute;
            top: 35px;
            left: 50%;
            background-color: white;
            border: 1px solid rgba(0,0,0,0.2);
            border-radius: 6px;
            box-shadow: 0px 0px 10px rgba(0,0,0,0.15);
            padding: 20px;
            width: 400px;
            margin-top: 20px;
            margin-left: -200px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
    
            .exit-modal{
                font-size: 20px;
                color: rgba(0,0,0,0.2);
                cursor: pointer;
                width: fit-content;
                align-self: flex-end;
                padding: 3px;
                position: absolute;
                right: 10px;
                top: 10px;

                &:hover{
                    background-color: #f1f1f1;
                    border-radius: 3px;
                }
            }

            h3{
                font-family: $heading-stack;
                font-weight: bold;
                font-size: 18px;
                margin-bottom: 20px;
            }

            .title-input{
                margin-bottom: 10px;
                width: 99%;
            }
    
            .btn-container{
                width: 60%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 20px;
                
            }
        }
    }

    .scheduled-event-wrapper{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 5vh;

        .loader-skeleton{
            margin: 10px 0px;
            height: 50px;
            width: 100%;
            border-radius: 5px;
        }

        .scheduled-event-container{
            width: 100%;
            border: 1px solid rgba(0,0,0,0.2);
            border-radius: 5px;
            height: 70px;
            margin: 10px 0px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 20px;
            h3{

                font-size: 16px;
                margin: 0;
                padding: 0;
                flex-grow: 1;
            }
            .schedule-details{
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 40%;
                min-width: 240px;
                height: 100%;
                margin-right: 50px;

                .time-container{
                    h3{
                        font-family: $heading-stack;
                        font-weight: bold;
                        margin: 0;
                        padding: 0;
                        font-size: 16px;
                    }
                    p{
                        margin: 0;
                        padding: 0;
                        font-size: 14px;
                    }
                }
            }
            .schedule-icon{
                font-size: 20px;
                color: rgba(0,0,0,0.3);
                &.time-icon{
                    margin-right: 20px;  
                }
                
                &.exit-icon{
                    padding: 5px;
                    border-radius: 3px;
                    cursor: pointer;

                    &:hover{
                        background-color: rgba(0,0,0,0.1);
                    }

                }
            }
        }
    }

}