@import '../../variables.scss';

.user-settings-page{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;

    .settings-navigation{
        height: 100%;
        padding: 20px 0px 0px 0px;
        box-shadow: 4px 0px 5px rgba(0,0,0,0.1);
        font-size: 16px;
        width: 220px;
        position: relative;

        .menu-item{
            height: 60px;
            display: flex;
            padding-left: 25px;
            align-items: center;
            
            &.back{
                position: absolute;
                bottom: 0;
                border-top: 1px solid rgba(0,0,0,0.1);
                width: 100%;
                padding-left: 50px;
            }
        }
    }

    .main-settings-container{
        border-radius: 8px;
        padding: 20px;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: relative;

        .form-wrapper{
            width: 100%;
            // height: 90%;
            align-self: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 0;
        }

        .back-btn{
            position: absolute;
            top: 15px;
            left: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            line-height: 20px;
            p{
                margin: 0 0 0 5px;
                padding: 0;
                font-size: 16px;
            }
        }

        
        .settings-row{
            width: 70%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 0;
            background-color: white;
            border: 1px solid rgba(0,0,0,0.2);
            border-radius: 8px;
            box-shadow: -4px 4px 5px rgba(0,0,0,0.1);
            padding: 20px;

            h1{
                font-family: $heading-stack;
                font-weight: bold;
                text-align: center;
                margin: 0px 0px 20px 0px;
                
            }

            h3{
                font-size: 18px;
                font-weight: bold;
                border-top: 1px solid rgba(0,0,0,0.1);
                width: 100%;
                padding-top: 20px;
            }

            .settings-container{
                border-radius: 8px;
                padding: 15px;
                width: 100%;
                margin: 0;
                display: flex;
                align-items: center;

                .upload-btn{
                    margin-left: 50px;
                }
                &.submit-btn{
                    border-radius: 0px;
                    border-top: 1px solid rgba(0,0,0,0.1);
                    width: 100%;
                    justify-content: flex-end;
                    align-items: flex-end;

                    .ant-form-item-control-input-content{
                        display: flex;
                        justify-content: flex-end;
                        width: 50%;
                        height: 80px;
                        align-items: center;
                        padding: 10px 5% 0 0;
                    }
                }
                .settings-input{
                    width: 45%;
                    margin-right: auto;
                    
                }
                
            }

            
        }

        
    }
}