@import '../../../../variables.scss';

.no-email-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50%;
    min-height: 20vh;

    .mail-icon{
        font-size: 40px;
        color: rgba(0,0,0,0.25);
    }

    h4{
        font-size: 18px;
        color: rgba(0,0,0,0.25);
        font-weight: bold;
        margin-top: 20px;
    }

}