@import '~antd/dist/antd.css';
@import './variables.scss';

.App{
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background-color: $background-color;
}
