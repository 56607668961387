@import '../../../../variables.scss';

.switch-container{
    background-color: $container-primary;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: 1px solid rgba(0,0,0,0.15);
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3vh;

    h2{
        font-family: $heading-stack;
        font-weight: bold;
    }

    .toggle-container{
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 70%;
        margin: 30px 0px;
            
        .ant-switch{
            height: 40px;
            width: 120px;
            min-width: 120px;
            max-width: 120px;
            width: fit-content;
            font-size: 16px;
            
            .ant-switch-inner{
                text-align: right;
                padding: 0;
                margin: 0;
                font-size: 14px;
                padding-right: 15px;
            }

            &.ant-switch-checked{
                .ant-switch-inner{
                    text-align: left;
                    font-size: 14px;
                    padding-left: 15px;
                }
            }
            .anticon-loading{
                height: 50px;
                width: 50px;
                margin-top: 10px;
                svg{
                    height: 40px;
                    width: 40px;
                    margin-top: 5px;
                    margin-left: -8px;
                }
            }
            div::before{
                height: 60px;
                width: 60px;
                border-radius: 100px;
                margin: -10px -20px 5px -25px;
                box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
                background-color: #f4f4f4;
                animation: none;
                display: flex;
                align-items: center;
                justify-content: center;
                display: flex;
                    
            } 
            
        }
    }
    
}