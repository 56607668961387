@import '../../variables.scss';

.dashboard-page{
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 2vw;


    .message-input{
        .ant-drawer-title{
            font-family: $heading-stack;
        }
        label{
            text-transform: uppercase;
            font-family: $heading-stack;
        }
    }

    .header-container{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        

        h1{
            font-family: $heading-stack;
            font-size: 35px;
            font-weight: bold;
            color: #313131;
            margin: 0;
            padding: 0;
        }
    }

    .dashboard-body{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 4vh;
        .column{
            display: flex;
            flex-direction: column;
            width: 49%;
            height: 75vh;
            padding: 0;
            
            .column-container{
                background-color: $container-primary;
                box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
                border-radius: 8px;
                border: 1px solid rgba(0,0,0,0.15);
                padding: 20px;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 3vh;
                
                h2{
                    font-family: $heading-stack;
                    font-weight: bold;

                }
            }
        }
    }
}