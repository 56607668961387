@import '../../../../variables.scss';

.accounts-container{
    background-color: $container-primary;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: 1px solid rgba(0,0,0,0.15);
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    
    h2{
        font-family: $heading-stack;
        font-weight: bold;

    }
    .account-btn{
        margin-top: 3vh;
    }

    .accounts-wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-height: 33vh;
        overflow: auto;

        .email-container{
            border: 1px solid  rgba(0,0,0,0.15);
            width: 90%;
            padding: 15px 10px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px;
            p{
                margin: 0;
                padding: 0;
                width: 40%;
                font-size: 16px;
            }
            .container-icon{
                color: rgba(0,0,0,0.3);
                font-size: 20px;
            }
            .option{
                font-size: 22px;
                margin-right: 20px;
                cursor: pointer;
            }
    
        }
    }

    .loader-skeleton{
        margin: 10px 0px;
        height: 50px;
        width: 100%;
        border-radius: 5px;
    }
}